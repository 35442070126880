import {
  Word,
} from '@/types/word'

export const newWord = (): Word => ({
  text: ``,
  translations: [
    ``,
  ],
})
